.nav_item_icon{
    height: 20%;
    width: 20%;
    padding-top: 25%;
    padding-bottom: 25%;
}

@media (max-width: 575.98px) {
    .nav_item_icon{
        height: 15%;
        width: 15%;
        padding-top: 10%;
        padding-bottom: 10%;
    }
    .nav_item_container{
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .nav_item_icon{
        height: 20%;
        width: 20%;
        padding-top: 25%;
        padding-bottom: 25%;
    }
    .nav_item_container{
        padding-left: 30%;
        padding-right: 30%;
        margin-top: 5%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) { 
    .nav_item_icon{
        height: 20%;
        width: 20%;
        padding-top: 25%;
        padding-bottom: 25%;
    }
}