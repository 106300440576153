.header_title{
    font-size: 55px;
    font-weight: bold;
    color: white;
    text-align:center;
}

.header_content{
    font-size: 15px;
    font-weight: 800;
    color: white;
    text-align:center;
}

.header_btn{
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding-top: 15px;
    background-color: white;
    width: 20%;
}

.bc_btn{
    padding: 15px;
    background-color: white;
    width: 30%;
  }
  
  .hash_tag_text{
    font-size: 15px;
      font-weight: bold;
  }

@media (max-width: 575.98px) {
    .header_title{
        font-size: 35px;
        font-weight: bold;
        color: #FFFFFF;
    text-align:center;
    }
    
    .header_content{
        font-size: 14px;
        font-weight: 800;
        color: #FFFFFF;
    text-align:center;
    }
    
    .header_btn{
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        padding-top: 15px;
        background-color: white;
        width: 50%;
    }

    .bc_btn{
        padding: 15px;
        background-color: white;
        width: 80%;
      }
      .hash_tag_text{
        font-size: 12px;
          font-weight: bold;
      }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    
    .header_btn{
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        padding-top: 15px;
        background-color: white;
        width: 30%;
    }

    .bc_btn{
        padding: 15px;
        background-color: white;
        width: 50%;
      }
      .hash_tag_text{
        font-size: 12px;
          font-weight: bold;
      }
}
@media (min-width: 768px) and (max-width: 991.98px) { 
    
    .header_btn{
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        padding-top: 15px;
        background-color: white;
        width: 40%;
    }

    .bc_btn{
        padding: 15px;
        background-color: white;
        width: 40%;
      }
      .hash_tag_text{
        font-size: 12px;
          font-weight: bold;
      }
}