.session_title{
    color: #EC5A3B;
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 19px;
}

.session_sub_text{
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.back_arrow{
    height: 50px;
    width: 50px;
}

@media (max-width: 575.98px) {
    .session_title{
        color: #EC5A3B;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 16px;
    }
    .back_arrow{
        height: 30px;
        width: 30px;
    }
    .session_sub_text{
        color: white;
        font-size: 12px;
        font-weight: bold;
    }
    
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .session_title{
        color: #EC5A3B;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 16px;
    }
    .back_arrow{
        height: 20px;
        width: 20px;
    }
    .session_sub_text{
        color: white;
        font-size: 12px;
        font-weight: bold;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) { 
}