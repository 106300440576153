.header_text {
    color: '#fff';
    font-size: '40px';
    font-weight: 'bold';
    text-align: 'center'
}

@media (max-width: 575.98px) {
    .header_text {
        color: '#fff';
        font-size: '20px';
    }
}