*{
  /* overflow-x:hidden; */
  scroll-behavior: smooth;
  font-family: din-2014, sans-serif;
}

.about_title{
  font-size: 50px;
  font-weight: bold;
  color: white;
}

.centered-content {
  justify-content: center; /* Center children vertically */
  text-align: center; /* Ensure text within children is also centered */
  height: 100%; /* Adjust this as needed */
}

.carousel-item {
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.features_image{
  padding-left: 10%;
}

.about_sub_title{
  font-size: 15px;
  font-weight: bold;
  color: #FF7D00;
}

.pagaragraph{
  font-size: 15px;
  font-weight: 800;
  color: white;
}

.features_list{
}

.features_list_label{
  font-size: 15px;
  font-weight:800;
  margin:0;
}

.about_image{
  height: 360px;
  width:100%;
  object-fit:contain;
}

.train_with_us_btn{
  position: absolute;
  bottom: 10%;
  right: 20%;
  color:black;
  font-size: 15px;
  font-weight: bold;
  background-color: #FFFFFF;
  padding: 10px 105px;
}

.train_with_myo_btn{
  position: absolute;
  bottom: 12%;
  right: 19%;
  color:black;
  font-size: 15px;
  font-weight: bold;
  background-color: #FFFFFF;
  padding: 10px 105px;
}

.title{
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: white;
}

.sub-title{
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #FF7D00;
}

.service_label{
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #FFFFFF;
}

.bc_title{
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: white;
}

.bc_content{
  font-size: 15px;
  font-weight: 800;
  color: white;
  text-align: center;
}

.bc_btn{
  padding: 15px;
  background-color: white;
  width: 30%;
}

.hash_tag_text{
  font-size: 15px;
    font-weight: bold;
}

.testimonial-name{
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #FFFFFF;
}

/* // `sm` applies to x-small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

  .about_title{
    font-size: 30px;
    font-weight: bold;
    color: #FFFFFF;
  }

  .features_image{
    padding-left: 0%;
  }
  
  .about_sub_title{
    font-size: 14px;
    font-weight: bold;
    color: #FF7D00;
  }
  
  .pagaragraph{
    font-size: 14px;
    font-weight: 800;
    color: #FFFFFF;
  }

  .features_list{
margin-left: auto;
  }

  .features_list_label{
    font-size: 14px;
    font-weight:800;
  margin:0;
  }
  .train_with_us_btn{
    position: absolute;
    bottom: 10%;
    right: 20%;
    color:black;
    font-size: 15px;
    font-weight: bold;
    background-color: #FFFFFF;
    padding: 10px 60px;
  }

  .train_with_myo_btn{
    position: absolute;
    bottom: 12%;
    right: 18%;
    color:black;
    font-size: 15px;
    font-weight: bold;
    background-color: #FFFFFF;
    padding: 10px 60px;
  }

  .title{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: white;
  }
  
  .sub-title{
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #FF7D00;
  }
  
  .service_label{
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #FFFFFF;
  }

  .bc_title{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: white;
  }
  
  .bc_content{
    font-size: 14px;
    font-weight: 800;
    color: white;
    text-align: center;
  }

  .bc_btn{
    padding: 15px;
    background-color: white;
    width: 80%;
  }
  .hash_tag_text{
    font-size: 12px;
      font-weight: bold;
  }
 }

 /* //landscape */
 @media (min-width: 576px) and (max-width: 767.98px) {
  .train_with_us_btn{
    position: absolute;
    bottom: 10%;
    right: 24%;
    color:black;
    font-size: 15px;
    font-weight: bold;
    background-color: #FFFFFF;
    padding: 10px 100px;
  }  

  .features_image{
    padding-left: 0%;
  }

  .train_with_myo_btn{
    position: absolute;
    bottom: 12%;
    right: 24%;
    color:black;
    font-size: 15px;
    font-weight: bold;
    background-color: #FFFFFF;
    padding: 10px 100px;
  }

  .bc_title{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: white;
  }
  
  .bc_content{
    font-size: 14px;
    font-weight: 800;
    color: white;
    text-align: center;
  }

  .bc_btn{
    padding: 15px;
    background-color: white;
    width: 50%;
  }
  .hash_tag_text{
    font-size: 12px;
      font-weight: bold;
  }
 }

/* // `lg` applies to medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) { 
  .train_with_us_btn{
    position: absolute;
    bottom: 8%;
    right: 20%;
    color:black;
    font-size: 10px;
    font-weight: bold;
    background-color: #FFFFFF;
    padding: 10px 60px;
  }

  .features_image{
    padding-left: 0%;
  }

  .train_with_myo_btn{
    position: absolute;
    bottom: 8%;
    right: 15%;
    color:black;
    font-size: 15px;
    font-weight: bold;
    background-color: #FFFFFF;
    padding: 10px 60px;
  }

  .bc_btn{
    padding: 15px;
    background-color: white;
    width: 40%;
  }
  .hash_tag_text{
    font-size: 12px;
      font-weight: bold;
  }

  .about_image{
    height: 440px;
    width:350px;
    object-fit: contain;
  }  

 }

@media (min-width: 991.98px) and (max-width: 1100px) { 
  .train_with_us_btn{
    position: absolute;
    bottom: 10%;
    right: 18%;
    color:black;
    font-size: 10px;
    font-weight: bold;
    background-color: #FFFFFF;
    padding: 10px 105px;
  }

  .train_with_myo_btn{
    position: absolute;
    bottom: 10%;
    right: 15%;
    color:black;
    font-size: 15px;
    font-weight: bold;
    background-color: #FFFFFF;
    padding: 10px 105px;
  }

  .about_image{
    height: 425px;
    width:400px;
    object-fit: contain;
  }  
}