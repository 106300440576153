.contact_header_title{
    font-family: din-2014, sans-serif;
    font-size: 50px;
    font-weight: bold;
    color: white;
    text-align: center;
    font-style: normal;
}

.heading{
    font-family: din-2014, sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: #FF7D00;
    line-height: 100%;
    margin:0
}

.header_bottom{
    position: absolute;
    bottom: 10%;
    width: 100%;
}

@media (max-width: 575.98px) {
    .contact_header_title{
        font-size: 30px;
        font-weight: bold;
        color: white;
        text-align: center;
    }
    .header_bottom{
        position: absolute;
        bottom: 5%;
        width: 100%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {

    .contact_header_title {
        font-size: 30px;
        font-weight: bold;
        color: white;
        display: none;
        text-align: center;
    }

}
@media (min-width: 768px) and (max-width: 991.98px) { 
    .contact_header_title{
        font-size: 30px;
        font-weight: bold;
        color: white;
        text-align: center;
    }
    
}