.physio_title{
    font-size: 55px;
    font-weight: bold;
    color: #FFFFFF;
}
  
  .physio_label{
    font-size: 15px;
    font-weight: bold;
    color: #FF7D00;
    margin: 0px;
  }
  
  .physio_content{
    font-size: 15px;
    font-weight: 800;
    color: #FFFFFF;
}

.physio_textbox{
    background-color: transparent;
    border: 2px solid white ;
    width: 100%;
    height: 3rem;
    color: white;
    outline: 0;
    text-align: center;
}

.physio_textarea{
  background-color: transparent;
  border: 2px solid white ;
  width: 100%;
  height: 6rem;
  color: white;
  outline: 0;
  text-align: center;
}

.physio_drop_down{
    background-color: transparent;
    border: 2px solid white;
    width: 100%;
    height: 3rem;
    color: white;
    text-align: center;
    outline: 0;
    text-align-last: center;
    -moz-text-align-last: center;
  }

  .physio_file_upload{
    background-color: transparent;
    border: 2px solid white;
    width: 100%;
    height: 20rem;
    color: white;
    text-align: center;
    outline: 0;
  }
  
  .physio_submit_button{
    text-align: center;
    background-color: #FFFFFF;
    width: 100%;
    outline: 0;
  }

  .physio_submit_button_text{
    font-size: 15px;
    font-weight: bold;
  }

  .physio_submit_warning{
    color: white;
    font-size: 15px;
    font-weight: bold;
    margin: 0px;
  }

::placeholder{
    text-align: center;
    color: white;
    vertical-align:middle;
    line-height: 6rem;
    font-weight: bold;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-align: center;
  color: white;
  vertical-align:middle;
  line-height: 6rem;
  font-weight: bold;
}
::-webkit-input-placeholder { /* Webkit */
  text-align: center;
  color: white;
  vertical-align:middle;
  line-height: 6rem;
  font-weight: bold;
}
:-ms-input-placeholder { /* IE */
  text-align: center;
  color: white;
  vertical-align:middle;
  line-height: 6rem;
  font-weight: bold;
}

.Calendar {
  height: 100%;
  width: 80%;
  margin: auto;
  margin-top:50px;
  margin-bottom: 50px;
  /* Text Colour to White */
  color: white;
  /* background: yellow; */
}

@media (max-width: 575.98px) {
    .physio_title{
        font-size: 30px;
        font-weight: bold;
        color: #FFFFFF;
      }
      
      .physio_label{
        font-size: 12px;
        font-weight: bold;
        color: #FF7D00;
      }
      
      .physio_content{
        font-size: 12px;
        font-weight: 800;
        color: #FFFFFF;
      }
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) { 
}